import { TokenParams } from '@shared/types/token-params';

export function getTokenParams(): TokenParams {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  return {
    token: queryParams.get('token') ?? '',
    instance: queryParams.get('instance') ?? '',
    gameUuid: queryParams.get('gameUuid') ?? '',
  };
}
