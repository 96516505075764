import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { GameService } from '@services/game.service';
import { SessionService } from '@services/session.service';
import { take, filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  user$ = this.session.user$;
  isMobile$ = this.session.isMobile$;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private session: SessionService,
    private router: Router,
    private gameService: GameService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.updateBodyClass();
    this.initGame();
    this.checkRouting();
  }

  private initGame(): void {
    this.user$
      .pipe(
        take(1),
        filter((user) => !!user),
      )
      .subscribe(() => {
        this.gameService.fetchGame().subscribe();
      });
  }

  private checkRouting(): void {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        const extras = this.session.thirdPartyToken
          ? {
              queryParams: {
                token: this.session.thirdPartyToken,
                gameUuid: this.session.gameUuid,
              },
            }
          : undefined;
        const urlToNavigate = window.location.pathname;
        if (result.matches) {
          this.session.setIsMobile(true);
          if (!urlToNavigate.includes('/m')) {
            const url = urlToNavigate.includes('/d')
              ? urlToNavigate.replace('/d', '/m')
              : '/m';
            this.router.navigate([url], extras);
          }
        } else if (!result.matches) {
          this.session.setIsMobile(false);
          if (!urlToNavigate.includes('/d')) {
            const url = urlToNavigate.includes('/m')
              ? urlToNavigate.replace('/m', '/d')
              : '/d';
            this.router.navigate([url], extras);
          }
        }
      });
  }

  private updateBodyClass(): void {
    const bodyClass = 'mobile-version';
    this.isMobile$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
      if (isMobile) {
        this.document.body.classList.add(bodyClass);
      } else {
        this.document.body.classList.remove(bodyClass);
      }
    });
  }
}
